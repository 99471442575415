import { createI18n } from "vue-i18n";
const i18n = createI18n({
  locale: sessionStorage.getItem("language") || "en",
  messages: {
    en: {
      work: `HOW DOES IT WORK`,
      benefits: `BENEFITS`,
      navigation: `Navigation`,
      terms: `Terms and Conditions`,
      privacy: `Notice of Privacy`,
      about: `About us`,
      office: `Our registered office: Av. Paseo de las Palmas 735, Lomas de Chapultepec I Secc, Miguel Hidalgo, 11000 Mexico City, CDMX.`,
      rights: `All rights reserved. By using this site, you agree to our privacy policy as well as our terms and conditions.`,
      hello: "hola",
      home1: `Answer 5 questions, and we will show you the perfect match for you!`,
      home2: `3...2...1...Go!.`,
      work1: `How we work?`,
      workStepsHeader1: `Step. 1 Click on “I want to know my ideal car“`,
      workStepsHeader2: `Step 2. Answer the 5 questions`,
      workStepsHeader3: `Step 3. Meet your car`,
      workSteps1: `I want to know my ideal car`,
      workSteps2: `Our smart system will analyze your data, and we will show you your best matches based on your information.`,
      workSteps3: `We will automatically redirect you to Whatsapp, to an agent can contact you asap.`,
      bigBenefits: `Big benefits`,
      benefitsCard1Header: `100% online`,
      benefitsCard1: `From home, work, or wherever you want. Just answer 5 questions, and let us show you your ideal car.`,
      benefitsCard2Header: ` We are committed to you`,
      benefitsCard2: `We are partners with the most recognized car brands in the world. We will only show you suitable options based on your profile.`,
      benefitsCard3Header: `100% free`,
      benefitsCard3: `You can do the process as many times as you want. This service is 100% free for you!`,
      testimonialsHeader1: `Our clients`,
      testimonialsHeader2: `highly recommend us`,
      client1: `“This services is just amazing. I went through the questions in 2 minutes, and in the next day I had a new car in my garage.”`,
      client2: `“Wonderful, they show you just what you need according to your profile. Super recommended.”`,
      client3: `“I've always had a hard time choosing the right car. ${process.env.VUE_APP_TITLE} helped me fast and for free”`,
      button1: `Start!`,
      button2: `I WANT TO KNOW MY IDEAL CAR`,
      button3: `SHOW ME MY PERFECT CAR`,
      buttonWA: `GO TO WHATSAPP`,
      buttonNext: `NEXT`,
      buttonFinish: `FINISH`,
      buttonBack: `BACK`,
      dropdownPlaceHolder: `Select an option`,
      emailPlaceHolder: `Enter your e-mail address`,
      obligatoryField: "This field is required",
      validationSelect: "Wrong field. Select an option.",
      validationEmail: "Wrong field. Enter a valid email.",
      validationPhone: `Wrong field. Enter a ${process.env.VUE_APP_PHONE_LENGTH} digit number.`,
      placeholder: ``,
      spinnerText: `1 second please.`,
      spinnerTextResults: `We are getting the best options for you.`,
      results1: `Thank you very much for finishing your application.`,
      results2: `Very soon an agent will contact you to show you the best options. please keep an eye on your cell phone.`,
      privacyHeader: `Aviso de privacidad`,
      privacyDate: `FECHA DE EMISIÓN: 10/2022`,
      privacyParagraph1: `Es propiedad de y es operado por Multigenios SA de CV. El
      siguiente Aviso de privacidad protege tu información personal
      de acuerdo con la Ley Federal de Protección de Datos en
      Posesión de los Particulares. Este Aviso de privacidad explica
      cómo recolectaremos y usaremos algunos de tus datos
      personales, laborales, financieros y patrimoniales, así como
      las medidas de control y prevención tomados para asegurar que
      tu información esté segura y permanezca confidencial.`,
      privacyParagraph2Header: `Responsable`,
      privacyParagraph2a: `En cumplimiento con la Ley Federal de Protección de Datos
      Personales en Posesión de los Particulares, Multigenios SA de
      CV, (en adelante`,
      privacyParagraph2b: `te informa que`,
      privacyParagraph2c: ` es responsable de la recolección, utilización, almacenamiento,
      comunicación y protección de tus datos personales. El "Usuario" podrá contactar a`,
      privacyParagraph2d: `en cualquier momento a través del correo electrónico`,
      privacyParagraph2e: `para el ejercicio de sus derechos ARCO.`,
      privacyParagraph3a: `A través de mecanismos administrativos, técnicos y físicos,
      como encriptación de datos o uso de contraseñas para acceder a
      información confidencial, entre otras,`,
      privacyParagraph3b: `protege y salvaguarda tus datos personales financieros y patrimoniales
      para evitar el daño, pérdida, destrucción, robo, extravío, alteración o tratamiento no autorizado de los mismos.`,
      pricavyParagraph4Header: `Información personal`,
      privacyParagraph4a: `La información personal solicitada al usuario está compuesta
      por los siguientes rubros: nombre(s), apellido(s), fecha de
      nacimiento, sexo, correo electrónico, teléfono fijo, teléfono
      celular, domicilio, estado, delegación o municipio, calle,
      número exterior e interior, país, ingreso mensual, información
      patrimonial, referencias personales, datos médicos y valor del
      inmueble sujeto a garantía hipotecaria. Tus datos personales
      serán tratados con base en los principios de licitud,
      consentimiento, información, calidad, finalidad, lealtad,
      proporcionalidad y responsabilidad de la Ley Federal de
      Protección de Datos Personales en Posesión de los
      Particulares.`,
      privacyParagraph5a: `mantendrá la confidencialidad de tus datos personales
      estableciendo y manteniendo de forma efectiva, mediante
      medidas de seguridad administrativas, técnicas y físicas como
      encriptación de datos y uso de contraseñas, entre otras, para
      evitar su daño, pérdida, alteración, destrucción, uso, acceso
      o divulgación indebida. La información deberá ser veraz y
      completa. El usuario responderá en todo momento por los datos
      proporcionados y en ningún caso`,
      privacyParagraph5b: `será
      responsable de la veracidad de los mismos.`,
      pricavyParagraph6Header: `Uso de los datos personales`,
      privacyParagraph6a: `En caso de que aceptes los términos de este Aviso de
      Privacidad, tus datos personales serán usados para:`,
      privacyParagraph6ali1a: `Prestar el servicio de asesoría, comparación, simulación y
      cálculo de cotizaciones de productos financieros, de seguros
      y de inmuebles. Como parte de la prestación del servicio,`,
      privacyParagraph6ali1b: `transferirá tus datos personales a instituciones bancarias o
      entidades financieras generales, aseguradoras, brokers
      hipotecarios y/o inmobiliarios, desarrolladores
      inmobiliarios y entidades de investigación crediticia, con
      la finalidad de aplicar a sus propios procesos de solicitud
      de cotización de productos financieros, de seguros y de
      inmuebles, para poder ofrecerte el producto que se considere
      adecuado a tus necesidades.`,
      privacyParagraph6ali2a: `El enlace con instituciones bancarias o entidades
      financieras generales, aseguradoras, brokers hipotecarios
      y/o inmobiliarios, desarrolladores inmobiliarios y entidades
      de investigación crediticia. De igual manera estos datos
      serán utilizados para:`,
      privacyParagraph6ali2a1a: `Procurar un servicio eficiente.`,
      privacyParagraph6ali2a2a: `Informarte sobre cambios o nuevos servicios que estén
      relacionados con el producto o servicio solicitado.`,
      privacyParagraph6ali2a3a: `Dar cumplimiento a obligaciones contraídas con los
      usuarios.`,
      privacyParagraph6ali2a4a: `Evaluar la calidad de nuestro servicio.`,
      pricavyParagraph7Header: `Consulta de datos personales`,
      privacyParagraph7a: `Como parte de los términos y condiciones, el usuario autoriza
      por única ocasión a`,
      privacyParagraph7b: `crediticios ante las Sociedades de Información Crediticia que
      estime conveniente, declarando que conoce la naturaleza, alcance y uso que`,
      privacyParagraph7c: `hará de tal información.`,
      privacyParagraph8a: `Entiéndase por terceros a cualquier banco y/o entidad
      financiera y/o aseguradora y/o cualquier compañía que preste
      cualquier tipo de servicio financiero.`,
      pricavyParagraph9Header: `Limitación de usos y divulgación de los datos personales`,
      privacyParagraph9a: `En nuestro programa de notificación de promociones, boletín de
      noticias, blog, ofertas y servicios a través de correo
      electrónico, solo`,
      privacyParagraph9b: `tiene
      acceso a la información recabada. Este tipo de publicidad y
      comunicación se realiza mediante avisos y mensajes
      promocionales de correo electrónico, redes sociales y/o
      telefonía móvil, los cuales solo serán enviados a ti y a los
      contactos registrados para tal propósito. La suscripción a
      este servicio podrá modificarse en cualquier momento
      escribiendo a`,
      privacyParagraph9c: `. En los correos electrónicos enviados pueden incluirse,
      ocasionalmente, ofertas de terceras partes que sean nuestros
      socios comerciales.`,
      pricavyParagraph10aHeader: `¿Qué son las cookies y cómo se utilizan las cookies?`,
      privacyParagraph10a: `Son pequeñas piezas de información que son enviadas por el
      sitio web a tu navegador y se almacenan en el disco duro de tu
      equipo. Se utilizan para determinar tus preferencias cuando te
      conectas a los servicios de nuestros sitios, así como para
      rastrear determinados comportamientos o actividades llevadas a
      cabo por ti dentro del portal. Existen secciones de la página
      web en las que requerimos que el usuario tenga habilitadas las
      cookies, ya que algunas de las funcionalidades del sitio web
      las necesitan para trabajar.`,
      privacyParagraph11a: `Las cookies nos permiten:`,
      privacyParagraph11ali1a: `a) Reconocerte al momento de entrar a nuestros sitios y
      ofrecerte una experiencia personalizada.`,
      privacyParagraph11ali2a: `b) Conocer la configuración personal del sitio especificada
      por ti; por ejemplo, las cookies nos permiten detectar el
      ancho de banda que has seleccionado al momento de ingresar a
      la página de inicio de nuestros sitios, así sabemos qué tipo
      de información es aconsejable descargar.`,
      privacyParagraph11ali3a: `c) Calcular el tamaño de nuestra audiencia y medir algunos
      parámetros de tráfico. Cada navegador que obtiene acceso a
      nuestros sitios adquiere una cookie que se usa para
      determinar la frecuencia de uso y las secciones de los
      sitios visitadas, reflejando así hábitos y preferencias,
      información que nos es útil para mejorar el contenido, los
      titulares y las promociones para los usuarios.`,
      privacyParagraph12a: `Las cookies también nos ayudan a rastrear algunas actividades.
      Por ejemplo, en algunas de las encuestas que lanzamos en línea
      podemos utilizar cookies para detectar si el usuario ya ha
      llenado la encuesta y evitar desplegarla nuevamente. Las
      cookies te permitirán aprovechar los servicios que te
      ofrecemos, por lo que te recomendamos mantenerlas activadas.`,
      privacyParagraph13a: `Las cookies no serán utilizadas para identificar a los
      usuarios, con excepción de los casos en que se investiguen
      posibles actividades fraudulentas.`,
      privacyParagraph14a: `En el caso de que sea necesaria la utilización de cookies, el
      botón de “ayuda” que se encuentra en la barra de herramientas
      de la mayoría de los navegadores te dirá cómo aceptar nuevas
      cookies, cómo hacer que el navegador te notifique cuando
      recibes una nueva cookie o cómo deshabilitar todas las
      cookies.`,
      privacyParagraph15a: `Fernández & Rubí., titular del portal`,
      privacyParagraph15b: `almacena automáticamente los datos del usuario, por ejemplo,
      cuando se registra en el portal o cuando contacta a la empresa
      ya sea personalmente o respondiendo a preguntas por correo,
      redes sociales, fax, a través del portal o por teléfono.`,
      pricavyParagraph16Header: `Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)`,
      privacyParagraph16a: `En cumplimiento de lo dispuesto por los artículos 15, 16, 17,
      18 y 19 de la Ley Federal de Protección de Datos Personales en
      Posesión de Particulares, Multigenios SA de CV., titular del
      portal`,
      privacyParagraph16b: `designado a un Oficial de Datos como encargado del
      Departamento de Servicios y Protección de Datos Personales.
      Para comunicarte con el Oficial de Datos escribe un correo
      electrónico a`,
      privacyParagraph17a: `Para ejercer derechos ARCO y revocar consentimiento otorgado`,
      privacyParagraph18a: `Como titular de sus datos personales, el usuario podrá
      ejercitar los derechos ARCO (Acceso, Rectificación,
      Cancelación y Oposición al tratamiento de sus datos
      personales), o bien, revocar el consentimiento que haya
      otorgado a`,
      privacyParagraph18b: `para el tratamiento de sus datos, enviando directamente su
      solicitud al Oficial de Datos, encargado del Departamento de
      Servicio y Protección de Datos Personales. Para ejercer sus
      derechos ARCO, el usuario deberá enviar un correo electrónico
      a `,
      privacyParagraph19a: `Dicha solicitud deberá contener por lo menos: (a) nombre y
      domicilio u otro medio para comunicar la respuesta a tu
      solicitud; (b) los documentos que acrediten identidad o, en su
      caso, representación legal; (c) la descripción clara y precisa
      de los datos personales respecto de los que se solicita
      ejercer alguno de los derechos ARCO, (d) la manifestación
      expresa para revocar el consentimiento del tratamiento de tus
      datos personales y por tanto, darlos de baja para que no se
      usen; (d) cualquier otro elemento que facilite la localización
      de los datos personales.`,
      privacyParagraph20a: `La petición deberá ir acompañada de los fundamentos por los
      que se solicita dicha revocación y una identificación oficial
      del titular de los datos o de su apoderado. En un plazo máximo
      de 20 (veinte) días hábiles atenderemos la solicitud e
      informaremos sobre la procedencia de la misma a través del
      correo electrónico del que provenga la petición.`,
      privacyParagraph21a: ` solicita
      al usuario que actualice sus datos cada vez que éstos sean
      modificados, ya que esto permitirá brindarle un servicio
      eficiente y personalizado.`,
      pricavyParagraph22Header: `Transferencia de Información con Terceros`,
      privacyParagraph22a: `Los datos solicitados son indispensables para que`,
      privacyParagraph22b: ` te
      proporcione servicio, por lo que de no contar con los mismos,
      la sociedad se encuentra materialmente imposibilitada de
      cumplir el fin principal de la relación con sus usuarios. Como
      parte de la prestación del servicio,`,
      privacyParagraph22c: `transferirá tus datos personales a instituciones bancarias o
      entidades financieras generales, aseguradoras, brokers
      hipotecarios y/o inmobiliarios, desarrolladores inmobiliarios
      y entidades de investigación crediticia, con:`,
      privacyParagraph22ali1: `Compañías asociadas que tengan una relación comercial con`,
      privacyParagraph22ali2: `, así como sucursales de`,
      privacyParagraph22ali3: `Instituciones bancarias o entidades financieras generales,
      aseguradoras, brokers hipotecarios y/o inmobiliarios,
      desarrolladores inmobiliarios y entidades de investigación
      crediticia. Terceros cuyos productos o servicios aparezcan
      en nuestro sitio web y otorguen un servicio/producto
      solicitado por el usuario. Estos terceros tienen un control
      parcial de estos datos. Es importante que leas sus avisos de
      privacidad o los contactes directamente para información
      adicional.`,
      privacyParagraph22ali4: `Agencias de interferencia de fraudes para detectar fraudes y
      lavado de dinero, en caso de que información falsa o poco
      precisa haya sido otorgada.`,
      pricavyParagraph23Header: `Multigenios SA de CV, titular del portal`,
      privacyParagraph23a: `Multigenios SA de CV, titular del portal`,
      privacyParagraph23b: ` ha
      adoptado medidas de seguridad administrativas, técnicas y
      físicas como encriptación de datos o uso de contraseñas para
      acceder a información confidencial, entre otras, que permiten
      proteger los datos personales que sus usuarios proporcionan
      contra daño, pérdida, alteración, destrucción o su uso, acceso
      y/o tratamiento no autorizado. Asimismo, nuestro personal está
      debidamente capacitado para tratar los datos personales en el
      mayor marco de confidencialidad, privacidad, secrecía y
      absoluto cumplimiento de la Ley Federal de Protección de Datos
      Personales en Posesión de Particulares. Queda expresamente
      señalado que el servicio de asesoría, comparación, simulación,
      cálculo y cotización de productos financieros y seguros que
      presta Multigenios SA de CV. a través de su portal,`,
      privacyParagraph23c: `, lo hace
      de forma estimada con base en la información publicada por las
      instituciones financieras y de seguros, por lo que se pueden
      presentar cambios sin previo aviso. Multigenios SA de CV no es
      responsable de cerciorarse o comprobar la veracidad de la
      información proporcionada por las diversas instituciones
      financieras y de seguros, así como por instituciones
      gubernamentales; tampoco es responsable de asegurarse del
      cumplimiento de estas últimas de los ofrecimientos presentados
      en sus cotizaciones. Los resultados que su comparador arroja
      son para fines meramente ilustrativos.`,
      pricavyParagraph24Header: `Cambios en el aviso de privacidad`,
      privacyParagraph24a: `Nos reservamos el derecho de efectuar en cualquier momento
      modificaciones o actualizaciones al presente aviso de
      privacidad, para la atención de novedades legislativas o
      jurisprudenciales, políticas internas, nuevos requerimientos
      para la prestación u ofrecimiento de nuestros servicios o
      productos y prácticas del mercado. Las referidas
      modificaciones o actualizaciones serán publicadas y puestas a
      disposición del público a través del portal`,
      privacyParagraph24b: `, sección Aviso de privacidad.`,
      updated: `Actualizado 10/2022`,
      termsHeader: `Términos y Condiciones`,
      termsDate: `FECHA DE EMISIÓN: 02/2021`,
      termsParagraph1a: `Para el uso de`,
      termsParagraph1b: `-
      mediante la navegación a través de esta página web, usted
      acepta los términos y condiciones generales (los "Términos y
      Condiciones Generales") aplicables al uso de nuestra página
      web. Nos reservamos el derecho, a nuestra sola discreción, de
      cambiar, modificar o alterar de cualquier modo los términos y
      condiciones y la política de privacidad que aparecen en esta
      página en cualquier momento, por cualquier razón. Cualquier
      cambio, modificación o alteración se hará mediante aviso en la
      página, pero no se le avisará a usted directamente. El usuario
      debe leer, entender y aceptar todas las condiciones
      establecidas en los Términos y Condiciones Generales y en el
      Aviso de Privacidad, así como cualquier otro documento, previo
      a su inscripción como usuario de`,
      terms2Header: `Nuestros Servicios`,
      termsParagraph2a: `no vende
      automóviles, es un servicio gratuito que empareja a los
      consumidores con los vendedores de autos participantes en
      nuestra red directa de`,
      termsParagraph2b: `(“vendedores participantes“). Para utilizar este servicio,
      debe completar una o más solicitudes en línea que le
      solicitarán información específica sobre usted, incluyendo,
      aunque no está limitado a, su nombre, dirección, número de
      teléfono, dirección de correo electrónico, información de
      ingresos y modo de vida e información de empleo. La
      recopilación y uso de esta información está sujeto a la
      política de privacidad de esta página. Para poder utilizar
      esta página web o el servicio, debe aceptar los diferentes
      términos y condiciones establecidos en la política de
      privacidad que se incorporan aquí por referencia.`,
      termsParagraph3a: ` proporciona una conexión entre las personas que buscan
      préstamos de pago a corto plazo y los distintos proveedores en
      México que venden automóviles nuevos y/o usados.`,
      termsParagraph4a: `Una vez el consumidor envíe el formulario (s) de solicitud a`,
      termsParagraph4b: `enviará su información a uno o varios vendedores participantes
      . Al enviar el formulario (s) de solicitud, usted entiende y
      acepta que está enviando una consulta sobre un producto
      automóviles a`,
      termsParagraph4c: ` y a cada
      uno de los vendedores participantes a quienes`,
      termsParagraph4d: ` transmite su solicitud de préstamo. Al enviar el
      formulario ( s ) de solicitud, está enviando una invitación
      expresa a`,
      termsParagraph4e: ` y a cada
      uno de los vendedores participantes que compartirán su
      información con usted por cualquier medio (incluyendo correo
      electrónico, teléfono y correo directo) para que le puedan
      ayudar con su transacción o proporcionar información acerca de
      ofertas adicionales en las que pueda estar interesado. Si no
      desea recibir mensajes de correo electrónico puede solicitar
      ser eliminado de la lista de correo mediante el mecanismo de
      opt-out que aparece en los mensajes de correo electrónico
      recibidos. Para darse de baja de promociones enviadas por
      correo electrónico desde`,
      termsParagraph4f: `, envíe
      una solicitud física a Multigenios SA de CV: Gustavo Gomez
      Azcarate #5, Col. Reforma, Cuernavaca, Morelos, México, 62260`,
      termsParagraph5a: `Los vendedores participantes que reciben información de su
      solicitud revisan su información en tiempo real para
      determinar si la información proporcionada, cumple con los
      criterios para adquirir un auto. Los vendedores participantes
      podrán , entre otras cosas, verificar su identidad y revisar
      su información en bases de datos nacionales (como Experian,
      Llamada de crédito y otros) que rastrea las transacciones de
      consumo con las instituciones de crédito. Al enviar el
      formulario (s) de solicitud, usted está de acuerdo en permitir
      que los vendedores participantes revisen, verifiquen e
      investiguen dicha información.`,
      termsParagraph6a: `Si un vendedor participante determina que su solicitud cumple
      con sus criterios de calificación de para obtener un auto, la
      página web`,
      termsParagraph6b: `abrirá una nueva página web que identifica el nombre (s) y la
      información del vendedor (s) de contacto, o redirigirá el
      navegador a la página web de los vendedores. Usted entiende y
      acepta que, una vez haya sido redirigido a la página web de la
      entidad vendedora,`,
      termsParagraph6c: ` no tiene
      más participación en la solicitud de compra de auto. Sus
      interacciones subsecuentes con el prestamista están sujetas a
      la política de privacidad, condiciones de uso, y otras
      políticas y / o términos impuestos por ese prestamista.`,
      termsParagraph7a: ` no es un
      vendedor de autos, no acredita personas ni toma decisiones de
      crédito en relación con su servicio de búsqueda de automovil.`,
      termsParagraph7b: ` no
      garantiza la validacion de un precio en particular de un auto
      ni establece las condiciones de lla compra especifica con
      cualquier prestamista participante.`,
      termsParagraph7c: ` tampoco garantiza que el precio, los productos,
      disponibilidad, tarifas, tasas u otros términos de otros autos
      ofrecidos y puestos a disposición por los vendedores
      participantes, sean las mejores condiciones disponibles en el
      mercado.`,
      termsParagraph8a: ` no avala
      ni recomienda los productos de cualquier vendedor particular.`,
      termsParagraph8b: ` es un
      agente suyo o de cualquier vendedor participante.`,
      termsParagraph8c: ` no está involucrado con el uso de la entidad
      crediticia y / o revisión de la información de su solicitud
      para tomar una determinación acerca de si usted cumple o no
      con los criterios de calificación del para comprar el auto ,
      de un vendedor particular. El vendedor es el único responsable
      de sus servicios hacia usted y usted acepta que`,
      termsParagraph8d: ` no será
      responsable de los daños o gastos de cualquier tipo que surjan
      relacionados de alguna manera mediante su uso del servicio de
      búsqueda de auto. Entiende que los vendedores participantes
      podrán mantener la información de su solicitud, tanto si está
      como si no está calificado para una compra de automovil con
      ellos.`,
      terms9Header: `Derechos de Autor`,
      termsParagraph9a: `El contenido, organización, gráficos, diseño, compilación,
      traducción magnética, conversión digital y otros asuntos
      relacionados con la página están protegidos por derechos de
      autor, marcas registradas y otros derechos de propiedad
      (incluyendo la propiedad intelectual, aunque no es el único).
      La copia, redistribución, uso o publicación por su parte de
      tales asuntos o cualquier parte de la página web, excepto lo
      permitido por "Derecho de uso limitado" a continuación, está
      estrictamente prohibido. Usted no adquiere derechos de
      propiedad sobre cualquier contenido, documento u otros
      materiales vistos a través de la página. La publicación por`,
      termsParagraph9b: ` de
      información o materiales en la página no constituye una
      renuncia a cualquier derecho sobre dicha información y
      materiales.`,
      terms10Header: `Derecho de autor y marca de servicio de información:`,
      termsParagraph10a: `Derechos de autor © Multigenios SA de CV 2018, todos los
      derechos reservados. “`,
      termsParagraph10b: `” y los logotipos asociados son marcas de servicio de`,
      termsParagraph10c: ` y están
      protegidos en Estados Unidos, Reino Unido, España y México.
      Todas las demás marcas son propiedad de sus respectivos
      dueños.`,
      terms11Header: `Derecho de uso limitado:`,
      termsParagraph11a: `La visualización, impresión o descarga de cualquier contenido,
      video, audio, gráfico, formulario o documento desde la página
      le concede solamente una licencia no exclusiva y revocable
      para el uso exclusivamente personal y no comercial, según sea
      necesario para ver y escuchar el contenido y navegar a través
      de las páginas y enlaces disponibles en general al público
      mediante un navegador estándar de Internet y reproductor
      multimedia estándar, y no para la re publicación,
      distribución, cesión, sub-licencia, venta, preparación de
      trabajos derivados o cualquier otro uso. Ninguna parte de
      cualquier contenido, gráfico, formulario o documento puede ser
      reproducida en cualquier forma ni se puede incorporar en
      cualquier sistema de recuperación de información, ya sea
      electrónico o mecánico, que no sea para su uso personal y no
      comercial (pero no para la reventa o redistribución).`,
      terms12Header: `Edición, eliminación y modificación:`,
      termsParagraph12a: `Nos reservamos el derecho a nuestra discreción, de cambiar,
      modificar o eliminar cualquier documento, información u otro
      contenido que aparezca en la página.`,
      terms13Header: `Indemnización:`,
      termsParagraph13a: `Usted acepta eximir a`,
      termsParagraph13b: ` y sus
      respectivos directores, funcionarios, accionistas, empleados,
      agentes, abogados, anunciantes y editores (en conjunto,
      "Nuestras Partes Afiliadas") de cualquier responsabilidad,
      pérdida, reclamación o gastos, incluyendo los honorarios
      razonables de abogados, relacionados con su uso de la página o
      violación de este Acuerdo`,
      terms14Header: `Derecho Intransferible:`,
      termsParagraph14a: `Su derecho a utilizar la página no es transferible. Cualquier
      contraseña o derecho otorgado a usted para obtener información
      o documentos desde la página no es transferible.`,
      terms15Header: `Renuncia de garantía y limitación de responsabilidad :`,
      termsParagraph15a: `Los programas informáticos, información y demás contenidos
      disponibles a través de la página se proporcionan "tal cual",
      "según disponibilidad", y todas las garantías, expresas o
      implícitas, son rechazadas. En la medida de lo permitido por
      la ley aplicable,`,
      termsParagraph15b: ` rechaza toda garantía expresa o implícita, incluyendo,
      pero no limitándose a, (a) que ningún programa informático o
      la información disponible en, o a través de esta página, o la
      página misma sea comercial o adecuado para un propósito
      particular, (b ) que los programas o información disponible
      en, o a través de este sitio, o el sitio mismo será
      ininterrumpido o se verá libre de errores , (c) que los
      defectos serán corregidos, (d ) que no existen virus u otros
      componentes nocivos informáticos , ( e) que los métodos de
      seguridad empleados serán suficientes, (f) en relación con
      corrección, precisión o confiabilidad o (g) perjuicio del uso
      y disfrute de los programas o datos informáticos, o contra
      infracción. Se ofrece toda información y programas
      informáticos prestados en el curso de este acuerdo con todos
      sus defectos, y el riesgo en cuanto a la calidad, rendimiento,
      precisión y el esfuerzo del usuario. Nosotros y nuestros
      socios no tenemos ninguna responsabilidad respecto al uso de
      la información o cualquier otro contenido disponible desde la
      página, o en la página en sí. En particular, aunque no como
      una limitación del mismo, nosotros y nuestros socios no somos
      responsables por daños y perjuicios, daños indirectos,
      especiales o emergentes (incluidos los daños por pérdida de
      negocio, pérdida de beneficios, litigios o similares), ya sea
      basado en el incumplimiento del contrato, el incumplimiento de
      la garantía, agravio, responsabilidad del producto; ni por
      cualquier reclamación de terceros de cualquier naturaleza,
      incluso si se ha advertido de la posibilidad de tales daños o
      reclamos. La negación de daños establecida arriba es un
      elemento fundamental de la base de la negociación entre México
      y usted. La información y otro contenido está disponible en, o
      a través de esta página, y la página en sí, que no se
      ofrecería sin tales limitaciones. Ningún consejo o
      información, ya sea oral o escrita, que obtenga de México a
      través de la página creará ninguna garantía, representación o
      garantía que esté estipulada expresamente en este acuerdo.`,
      terms16Header: `Los enlaces a otras páginas web:`,
      termsParagraph16a: `La página puede ahora, o en el futuro, contener enlaces a
      páginas web de terceros. Nosotros no controlamos,
      investigamos, supervisamos o comprobamos dichas páginas web,
      no nos hacemos responsables de los programas de ordenador
      disponibles, contenido u opiniones expresadas en dichas
      páginas web, y no las investigamos, supervisamos o
      comprobamos. Proporcionamos dichos enlaces de terceros
      únicamente para comodidad de los visitantes de la página, y la
      inclusión de un enlace no implica la aprobación o la
      des-aprobación de la página vinculada por nosotros. Si usted
      decide abandonar la página y acceder a cualquier página web de
      terceros, lo hace bajo su propio riesgo.`,
      terms17Header: `Información y Comunicados de prensa:`,
      termsParagraph17a: `La página puede contener entrevistas, debates, comunicados de
      prensa y otra información (colectivamente, "Información")
      acerca de nosotros, nuestra empresa y nuestros servicios,
      incluyendo enlaces a páginas web de terceros que contengan
      dicha información, que se esté proporcionando a conveniencia
      de los visitantes de la página. A pesar de que toda la
      información preparada por`,
      termsParagraph17b: ` se cree
      exacta a partir de la fecha de preparación, negamos cualquier
      derecho u obligación de actualizar cualquier información. No
      se debe confiar en que declaraciones referentes a empresas
      distintas de`,
      termsParagraph17c: ` que estén
      contenidas en dicha información fueran facilitadas o endosadas
      por nosotros. Las opiniones expresadas en cualquier
      información, incluyendo los empleados y agentes de`,
      termsParagraph17d: `, son
      exclusivamente las del autor y no reflejan necesariamente el
      parecer de`,
      termsParagraph17e: `. Con
      respecto a la información que figura en los enlaces a páginas
      web de terceros, por favor consulte la sección "Enlaces a
      otras páginas web" arriba.`,
      terms18Header: `Comunicaciones de Visitantes:`,
      termsParagraph18a: `Salvo disposición expresa en contra nuestra, todos los
      comentarios, retroalimentación, información o materiales que
      usted envíe a través o en asociación con la página se
      considerará no confidencial. Al enviarnos dichos comentarios,
      opiniones, información o material:`,
      privacyParagraph18ali1a: ` Usted manifiesta y garantiza que el uso de`,
      privacyParagraph18ali1b: `es bajo su responsabilidad y no contraviene ningún acuerdo,
      no viola ninguna ley, o infringe los derechos de algún tercero`,
      privacyParagraph18ali2a: ` Usted manifiesta y garantiza que tiene
      todos los derechos para aceptar este acuerdo`,
      privacyParagraph18ali3a: ` Usted acepta que`,
      privacyParagraph18ali3b: `es libre de utilizar de cualquier forma la totalidad o parte
      de los contenidos de cualquier comunicación sin
      restricciones y sin la obligación de notificárselo,
      identificar o compensárselo de cualquier otra forma;`,
      privacyParagraph18ali4a: ` Usted concede a`,
      privacyParagraph18ali4b: `todos los derechos necesarios, incluyendo la renuncia a toda
      la privacidad y los derechos morales, a utilizar todos los
      comentarios, retroalimentación, información o material, en
      su totalidad o en parte, o como una obra derivada, sin
      ninguna obligación por parte`,
      privacyParagraph18ali4c: ` a cualquiera que sea. No aceptamos ideas no solicitadas, obras
      u otros materiales y usted reconoce que usted es responsable
      de asumir todos los riesgos relativos a la utilización o
      distribución de dichas ideas, obras o materiales.`,
      privacyParagraph18ali5a: `prohíbe expresamente la localización de las direcciones de
      correo electrónico desde cualquier página web (incluyendo
      todas las áreas de esta página - miembros y no miembros) y
      opta expresamente por no recibir mensajes de correo
      electrónico comerciales dirigidos a direcciones de correo
      electrónico de`,
      privacyParagraph18ali5b: `con dominio en México que se hayan obtenido en violación de
      lo anterior, o mediante el uso de software automático de
      generación de direcciones.`,
      terms19Header: `Cookies:`,
      termsParagraph19a: ` hace uso de cookies. Las cookies son archivos que identifican en
      nuestro servidor a su ordenador. Las cookies por sí mismas no
      identifican al usuario individual, sino al ordenador
      utilizado. Muchas páginas hacen esto cada vez que un usuario
      visita su página con el fin de realizar un seguimiento de los
      flujos de tráfico. Los cookies propios sólo registran aquellas
      áreas de la página que hayan sido visitadas por el equipo y
      durante cierto tiempo. Usted puede evitar el uso de cookies
      mediante la configuración de su navegador web, pero tenga en
      cuenta que esto puede dificultar algunas de las
      funcionalidades de la página web.`,
      terms20Header: `Datos, Terceros y cumplimiento de la Ley de Protección de
      Datos Personales en Posesión de Terceros:`,
      termsParagraph20a: `Toda la información presentada en esta página se lleva a cabo
      de manera segura y transfiere a las partes interesadas a
      través de un servidor seguro, de conformidad con la Ley de
      Protección de Datos de 1998. Si pasamos su información a
      terceros nos aseguramos de que su información personal será
      tratada con la misma seguridad y confidencialidad`,
    },
    es: {
      work: `CÓMO FUNCIONA`,
      benefits: `BENEFICIOS`,
      navigation: `Navegación`,
      terms: `Términos y condiciones`,
      privacy: `Aviso de Privacidad`,
      about: `Sobre nosotros`,
      office: `Nuestra oficina registrada: Av. Paseo de las Palmas 735, Lomas de Chapultepec I Secc, Miguel Hidalgo, 11000 Ciudad de México, CDMX.`,
      rights: `Todos los derechos reservados. Al usar este sitio, aceptas nuestra política de privacidad, así como nuestros términos y condiciones.`,
      home1: `Responde 5 preguntas y te mostraremos el auto perfecto para ti!`,
      home2: `3...2...1...¡Vamos!`,
      work1: `¿Cómo funciona?`,
      workStepsHeader1: `Paso. 1 Haz click en “Comenzar“`,
      workStepsHeader2: `Paso. 2 Responde las 5 preguntas`,
      workStepsHeader3: `Paso 3. Conoce tu auto ideal`,
      workSteps1: `Comenzar`,
      workSteps2: `Nuestro sistema inteligente va a analizar tus datos, y con base en tus respuestas te mostraremos tu mejor opción.`,
      workSteps3: `Automáticamente te vamos a re dirigir a un chat de WhatsApp con un especialista, para que puedas conocer tu auto ideal.`,
      bigBenefits: `Grandes Beneficios`,
      benefitsCard1Header: `100% online`,
      benefitsCard1: `Desde tu casa, tu trabajo o desde donde tú quieras. Solo contesta 5 preguntas, y te mostraremos tu auto ideal.`,
      benefitsCard2Header: `Estamos comprometidos contigo`,
      benefitsCard2: `Somos socios de las marcas de autos más reconocidas del mundo. Únicamente te mostraremos las opciones más adecuadas para tu perfil.`,
      benefitsCard3Header: `100% free`,
      benefitsCard3: `Puedes hacer el proceso cuantas veces quieras, nuestro proceso es 100% gratuito para ti siempre.`,
      testimonialsHeader1: `Mira lo que dicen nuestros`,
      testimonialsHeader2: `clientes`,
      client1: `“El servicio es simplemente magnifico. Me tardé 2 minutos en responder las preguntas, y al día siguiente tenía mi auto nuevo en mi estacionamiento.”`,
      client2: `“Maravilloso. Te enseñan justo lo que necesitas de acuerdo con tu perfil. Muy recomendado.”`,
      client3: `“Siempre me ha costado elegir el coche adecuado. ${process.env.VUE_APP_TITLE} me ayudó rápidamente y gratis.”`,
      button1: `COMENZAR`,
      button2: `COMENZAR`,
      button3: `MUÉSTRAME MI AUTO PERFECTO`,
      buttonWA: `CONTINUAR A WHATSAPP`,
      buttonNext: `SIGUIENTE`,
      buttonFinish: `FINALIZAR`,
      buttonBack: `ATRAS`,
      dropdownPlaceHolder: `Selecciona una opción`,
      emailPlaceHolder: `Ingresa tu correo electrónico`,
      obligatoryField: "Este campo es obligatorio",
      validationSelect: "Campo incorrecto. Selecciona una opción.",
      validationEmail: "Campo incorrecto. Ingresa un correo válido.",
      validationPhone: `Campo incorrecto. Ingresa un número de ${process.env.VUE_APP_PHONE_LENGTH} dígitos.`,
      placeholder: `Introduce tu `,
      spinnerText: `Estamos procesando tu solicitud, espera un momento.`,
      spinnerTextResults: `Estamos obteniendo las mejores opciones para ti.`,
      results1: `Muchas gracias por la información.`,
      results2: `Muy pronto un agente se pondrá en contacto contigo. Por favor mantente al pendiente de tu móvil.`,
      privacyHeader: `Aviso de privacidad`,
      privacyDate: `FECHA DE EMISIÓN: 10/2022`,
      privacyParagraph1: `Es propiedad de y es operado por Multigenios SA de CV. El
      siguiente Aviso de privacidad protege tu información personal
      de acuerdo con la Ley Federal de Protección de Datos en
      Posesión de los Particulares. Este Aviso de privacidad explica
      cómo recolectaremos y usaremos algunos de tus datos
      personales, laborales, financieros y patrimoniales, así como
      las medidas de control y prevención tomados para asegurar que
      tu información esté segura y permanezca confidencial.`,
      privacyParagraph2Header: `Responsable`,
      privacyParagraph2a: `En cumplimiento con la Ley Federal de Protección de Datos
      Personales en Posesión de los Particulares, Multigenios SA de
      CV, (en adelante`,
      privacyParagraph2b: `te informa que`,
      privacyParagraph2c: ` es responsable de la recolección, utilización, almacenamiento,
      comunicación y protección de tus datos personales. El "Usuario" podrá contactar a`,
      privacyParagraph2d: `en cualquier momento a través del correo electrónico`,
      privacyParagraph2e: `para el ejercicio de sus derechos ARCO.`,
      privacyParagraph3a: `A través de mecanismos administrativos, técnicos y físicos,
      como encriptación de datos o uso de contraseñas para acceder a
      información confidencial, entre otras,`,
      privacyParagraph3b: `protege y salvaguarda tus datos personales financieros y patrimoniales
      para evitar el daño, pérdida, destrucción, robo, extravío, alteración o tratamiento no autorizado de los mismos.`,
      pricavyParagraph4Header: `Información personal`,
      privacyParagraph4a: `La información personal solicitada al usuario está compuesta
      por los siguientes rubros: nombre(s), apellido(s), fecha de
      nacimiento, sexo, correo electrónico, teléfono fijo, teléfono
      celular, domicilio, estado, delegación o municipio, calle,
      número exterior e interior, país, ingreso mensual, información
      patrimonial, referencias personales, datos médicos y valor del
      inmueble sujeto a garantía hipotecaria. Tus datos personales
      serán tratados con base en los principios de licitud,
      consentimiento, información, calidad, finalidad, lealtad,
      proporcionalidad y responsabilidad de la Ley Federal de
      Protección de Datos Personales en Posesión de los
      Particulares.`,
      privacyParagraph5a: `mantendrá la confidencialidad de tus datos personales
      estableciendo y manteniendo de forma efectiva, mediante
      medidas de seguridad administrativas, técnicas y físicas como
      encriptación de datos y uso de contraseñas, entre otras, para
      evitar su daño, pérdida, alteración, destrucción, uso, acceso
      o divulgación indebida. La información deberá ser veraz y
      completa. El usuario responderá en todo momento por los datos
      proporcionados y en ningún caso`,
      privacyParagraph5b: `será
      responsable de la veracidad de los mismos.`,
      pricavyParagraph6Header: `Uso de los datos personales`,
      privacyParagraph6a: `En caso de que aceptes los términos de este Aviso de
      Privacidad, tus datos personales serán usados para:`,
      privacyParagraph6ali1a: `Prestar el servicio de asesoría, comparación, simulación y
      cálculo de cotizaciones de productos financieros, de seguros
      y de inmuebles. Como parte de la prestación del servicio,`,
      privacyParagraph6ali1b: `transferirá tus datos personales a instituciones bancarias o
      entidades financieras generales, aseguradoras, brokers
      hipotecarios y/o inmobiliarios, desarrolladores
      inmobiliarios y entidades de investigación crediticia, con
      la finalidad de aplicar a sus propios procesos de solicitud
      de cotización de productos financieros, de seguros y de
      inmuebles, para poder ofrecerte el producto que se considere
      adecuado a tus necesidades.`,
      privacyParagraph6ali2a: `El enlace con instituciones bancarias o entidades
      financieras generales, aseguradoras, brokers hipotecarios
      y/o inmobiliarios, desarrolladores inmobiliarios y entidades
      de investigación crediticia. De igual manera estos datos
      serán utilizados para:`,
      privacyParagraph6ali2a1a: `Procurar un servicio eficiente.`,
      privacyParagraph6ali2a2a: `Informarte sobre cambios o nuevos servicios que estén
      relacionados con el producto o servicio solicitado.`,
      privacyParagraph6ali2a3a: `Dar cumplimiento a obligaciones contraídas con los
      usuarios.`,
      privacyParagraph6ali2a4a: `Evaluar la calidad de nuestro servicio.`,
      pricavyParagraph7Header: `Consulta de datos personales`,
      privacyParagraph7a: `Como parte de los términos y condiciones, el usuario autoriza
      por única ocasión a`,
      privacyParagraph7b: `crediticios ante las Sociedades de Información Crediticia que
      estime conveniente, declarando que conoce la naturaleza, alcance y uso que`,
      privacyParagraph7c: `hará de tal información.`,
      privacyParagraph8a: `Entiéndase por terceros a cualquier banco y/o entidad
      financiera y/o aseguradora y/o cualquier compañía que preste
      cualquier tipo de servicio financiero.`,
      pricavyParagraph9Header: `Limitación de usos y divulgación de los datos personales`,
      privacyParagraph9a: `En nuestro programa de notificación de promociones, boletín de
      noticias, blog, ofertas y servicios a través de correo
      electrónico, solo`,
      privacyParagraph9b: `tiene
      acceso a la información recabada. Este tipo de publicidad y
      comunicación se realiza mediante avisos y mensajes
      promocionales de correo electrónico, redes sociales y/o
      telefonía móvil, los cuales solo serán enviados a ti y a los
      contactos registrados para tal propósito. La suscripción a
      este servicio podrá modificarse en cualquier momento
      escribiendo a`,
      privacyParagraph9c: `. En los correos electrónicos enviados pueden incluirse,
      ocasionalmente, ofertas de terceras partes que sean nuestros
      socios comerciales.`,
      pricavyParagraph10aHeader: `¿Qué son las cookies y cómo se utilizan las cookies?`,
      privacyParagraph10a: `Son pequeñas piezas de información que son enviadas por el
      sitio web a tu navegador y se almacenan en el disco duro de tu
      equipo. Se utilizan para determinar tus preferencias cuando te
      conectas a los servicios de nuestros sitios, así como para
      rastrear determinados comportamientos o actividades llevadas a
      cabo por ti dentro del portal. Existen secciones de la página
      web en las que requerimos que el usuario tenga habilitadas las
      cookies, ya que algunas de las funcionalidades del sitio web
      las necesitan para trabajar.`,
      privacyParagraph11a: `Las cookies nos permiten:`,
      privacyParagraph11ali1a: `a) Reconocerte al momento de entrar a nuestros sitios y
      ofrecerte una experiencia personalizada.`,
      privacyParagraph11ali2a: `b) Conocer la configuración personal del sitio especificada
      por ti; por ejemplo, las cookies nos permiten detectar el
      ancho de banda que has seleccionado al momento de ingresar a
      la página de inicio de nuestros sitios, así sabemos qué tipo
      de información es aconsejable descargar.`,
      privacyParagraph11ali3a: `c) Calcular el tamaño de nuestra audiencia y medir algunos
      parámetros de tráfico. Cada navegador que obtiene acceso a
      nuestros sitios adquiere una cookie que se usa para
      determinar la frecuencia de uso y las secciones de los
      sitios visitadas, reflejando así hábitos y preferencias,
      información que nos es útil para mejorar el contenido, los
      titulares y las promociones para los usuarios.`,
      privacyParagraph12a: `Las cookies también nos ayudan a rastrear algunas actividades.
      Por ejemplo, en algunas de las encuestas que lanzamos en línea
      podemos utilizar cookies para detectar si el usuario ya ha
      llenado la encuesta y evitar desplegarla nuevamente. Las
      cookies te permitirán aprovechar los servicios que te
      ofrecemos, por lo que te recomendamos mantenerlas activadas.`,
      privacyParagraph13a: `Las cookies no serán utilizadas para identificar a los
      usuarios, con excepción de los casos en que se investiguen
      posibles actividades fraudulentas.`,
      privacyParagraph14a: `En el caso de que sea necesaria la utilización de cookies, el
      botón de “ayuda” que se encuentra en la barra de herramientas
      de la mayoría de los navegadores te dirá cómo aceptar nuevas
      cookies, cómo hacer que el navegador te notifique cuando
      recibes una nueva cookie o cómo deshabilitar todas las
      cookies.`,
      privacyParagraph15a: `Fernández & Rubí., titular del portal`,
      privacyParagraph15b: `almacena automáticamente los datos del usuario, por ejemplo,
      cuando se registra en el portal o cuando contacta a la empresa
      ya sea personalmente o respondiendo a preguntas por correo,
      redes sociales, fax, a través del portal o por teléfono.`,
      pricavyParagraph16Header: `Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)`,
      privacyParagraph16a: `En cumplimiento de lo dispuesto por los artículos 15, 16, 17,
      18 y 19 de la Ley Federal de Protección de Datos Personales en
      Posesión de Particulares, Multigenios SA de CV., titular del
      portal`,
      privacyParagraph16b: `designado a un Oficial de Datos como encargado del
      Departamento de Servicios y Protección de Datos Personales.
      Para comunicarte con el Oficial de Datos escribe un correo
      electrónico a`,
      privacyParagraph17a: `Para ejercer derechos ARCO y revocar consentimiento otorgado`,
      privacyParagraph18a: `Como titular de sus datos personales, el usuario podrá
      ejercitar los derechos ARCO (Acceso, Rectificación,
      Cancelación y Oposición al tratamiento de sus datos
      personales), o bien, revocar el consentimiento que haya
      otorgado a`,
      privacyParagraph18b: `para el tratamiento de sus datos, enviando directamente su
      solicitud al Oficial de Datos, encargado del Departamento de
      Servicio y Protección de Datos Personales. Para ejercer sus
      derechos ARCO, el usuario deberá enviar un correo electrónico
      a `,
      privacyParagraph19a: `Dicha solicitud deberá contener por lo menos: (a) nombre y
      domicilio u otro medio para comunicar la respuesta a tu
      solicitud; (b) los documentos que acrediten identidad o, en su
      caso, representación legal; (c) la descripción clara y precisa
      de los datos personales respecto de los que se solicita
      ejercer alguno de los derechos ARCO, (d) la manifestación
      expresa para revocar el consentimiento del tratamiento de tus
      datos personales y por tanto, darlos de baja para que no se
      usen; (d) cualquier otro elemento que facilite la localización
      de los datos personales.`,
      privacyParagraph20a: `La petición deberá ir acompañada de los fundamentos por los
      que se solicita dicha revocación y una identificación oficial
      del titular de los datos o de su apoderado. En un plazo máximo
      de 20 (veinte) días hábiles atenderemos la solicitud e
      informaremos sobre la procedencia de la misma a través del
      correo electrónico del que provenga la petición.`,
      privacyParagraph21a: ` solicita
      al usuario que actualice sus datos cada vez que éstos sean
      modificados, ya que esto permitirá brindarle un servicio
      eficiente y personalizado.`,
      pricavyParagraph22Header: `Transferencia de Información con Terceros`,
      privacyParagraph22a: `Los datos solicitados son indispensables para que`,
      privacyParagraph22b: ` te
      proporcione servicio, por lo que de no contar con los mismos,
      la sociedad se encuentra materialmente imposibilitada de
      cumplir el fin principal de la relación con sus usuarios. Como
      parte de la prestación del servicio,`,
      privacyParagraph22c: `transferirá tus datos personales a instituciones bancarias o
      entidades financieras generales, aseguradoras, brokers
      hipotecarios y/o inmobiliarios, desarrolladores inmobiliarios
      y entidades de investigación crediticia, con:`,
      privacyParagraph22ali1: `Compañías asociadas que tengan una relación comercial con`,
      privacyParagraph22ali2: `, así como sucursales de`,
      privacyParagraph22ali3: `Instituciones bancarias o entidades financieras generales,
      aseguradoras, brokers hipotecarios y/o inmobiliarios,
      desarrolladores inmobiliarios y entidades de investigación
      crediticia. Terceros cuyos productos o servicios aparezcan
      en nuestro sitio web y otorguen un servicio/producto
      solicitado por el usuario. Estos terceros tienen un control
      parcial de estos datos. Es importante que leas sus avisos de
      privacidad o los contactes directamente para información
      adicional.`,
      privacyParagraph22ali4: `Agencias de interferencia de fraudes para detectar fraudes y
      lavado de dinero, en caso de que información falsa o poco
      precisa haya sido otorgada.`,
      pricavyParagraph23Header: `Multigenios SA de CV, titular del portal`,
      privacyParagraph23a: `Multigenios SA de CV, titular del portal`,
      privacyParagraph23b: ` ha
      adoptado medidas de seguridad administrativas, técnicas y
      físicas como encriptación de datos o uso de contraseñas para
      acceder a información confidencial, entre otras, que permiten
      proteger los datos personales que sus usuarios proporcionan
      contra daño, pérdida, alteración, destrucción o su uso, acceso
      y/o tratamiento no autorizado. Asimismo, nuestro personal está
      debidamente capacitado para tratar los datos personales en el
      mayor marco de confidencialidad, privacidad, secrecía y
      absoluto cumplimiento de la Ley Federal de Protección de Datos
      Personales en Posesión de Particulares. Queda expresamente
      señalado que el servicio de asesoría, comparación, simulación,
      cálculo y cotización de productos financieros y seguros que
      presta Multigenios SA de CV. a través de su portal,`,
      privacyParagraph23c: `, lo hace
      de forma estimada con base en la información publicada por las
      instituciones financieras y de seguros, por lo que se pueden
      presentar cambios sin previo aviso. Multigenios SA de CV no es
      responsable de cerciorarse o comprobar la veracidad de la
      información proporcionada por las diversas instituciones
      financieras y de seguros, así como por instituciones
      gubernamentales; tampoco es responsable de asegurarse del
      cumplimiento de estas últimas de los ofrecimientos presentados
      en sus cotizaciones. Los resultados que su comparador arroja
      son para fines meramente ilustrativos.`,
      pricavyParagraph24Header: `Cambios en el aviso de privacidad`,
      privacyParagraph24a: `Nos reservamos el derecho de efectuar en cualquier momento
      modificaciones o actualizaciones al presente aviso de
      privacidad, para la atención de novedades legislativas o
      jurisprudenciales, políticas internas, nuevos requerimientos
      para la prestación u ofrecimiento de nuestros servicios o
      productos y prácticas del mercado. Las referidas
      modificaciones o actualizaciones serán publicadas y puestas a
      disposición del público a través del portal`,
      privacyParagraph24b: `, sección Aviso de privacidad.`,
      privacyUpdated: `Actualizado 10/2022`,
      termsHeader: `Términos y Condiciones`,
      termsDate: `FECHA DE EMISIÓN: 02/2021`,
      termsParagraph1a: `Para el uso de`,
      termsParagraph1b: `-
      mediante la navegación a través de esta página web, usted
      acepta los términos y condiciones generales (los "Términos y
      Condiciones Generales") aplicables al uso de nuestra página
      web. Nos reservamos el derecho, a nuestra sola discreción, de
      cambiar, modificar o alterar de cualquier modo los términos y
      condiciones y la política de privacidad que aparecen en esta
      página en cualquier momento, por cualquier razón. Cualquier
      cambio, modificación o alteración se hará mediante aviso en la
      página, pero no se le avisará a usted directamente. El usuario
      debe leer, entender y aceptar todas las condiciones
      establecidas en los Términos y Condiciones Generales y en el
      Aviso de Privacidad, así como cualquier otro documento, previo
      a su inscripción como usuario de`,
      terms2Header: `Nuestros Servicios`,
      termsParagraph2a: `no vende
      automóviles, es un servicio gratuito que empareja a los
      consumidores con los vendedores de autos participantes en
      nuestra red directa de`,
      termsParagraph2b: `(“vendedores participantes“). Para utilizar este servicio,
      debe completar una o más solicitudes en línea que le
      solicitarán información específica sobre usted, incluyendo,
      aunque no está limitado a, su nombre, dirección, número de
      teléfono, dirección de correo electrónico, información de
      ingresos y modo de vida e información de empleo. La
      recopilación y uso de esta información está sujeto a la
      política de privacidad de esta página. Para poder utilizar
      esta página web o el servicio, debe aceptar los diferentes
      términos y condiciones establecidos en la política de
      privacidad que se incorporan aquí por referencia.`,
      termsParagraph3a: ` proporciona una conexión entre las personas que buscan
      préstamos de pago a corto plazo y los distintos proveedores en
      México que venden automóviles nuevos y/o usados.`,
      termsParagraph4a: `Una vez el consumidor envíe el formulario (s) de solicitud a`,
      termsParagraph4b: `enviará su información a uno o varios vendedores participantes
      . Al enviar el formulario (s) de solicitud, usted entiende y
      acepta que está enviando una consulta sobre un producto
      automóviles a`,
      termsParagraph4c: ` y a cada
      uno de los vendedores participantes a quienes`,
      termsParagraph4d: ` transmite su solicitud de préstamo. Al enviar el
      formulario ( s ) de solicitud, está enviando una invitación
      expresa a`,
      termsParagraph4e: ` y a cada
      uno de los vendedores participantes que compartirán su
      información con usted por cualquier medio (incluyendo correo
      electrónico, teléfono y correo directo) para que le puedan
      ayudar con su transacción o proporcionar información acerca de
      ofertas adicionales en las que pueda estar interesado. Si no
      desea recibir mensajes de correo electrónico puede solicitar
      ser eliminado de la lista de correo mediante el mecanismo de
      opt-out que aparece en los mensajes de correo electrónico
      recibidos. Para darse de baja de promociones enviadas por
      correo electrónico desde`,
      termsParagraph4f: `, envíe
      una solicitud física a Multigenios SA de CV: Gustavo Gomez
      Azcarate #5, Col. Reforma, Cuernavaca, Morelos, México, 62260`,
      termsParagraph5a: `Los vendedores participantes que reciben información de su
      solicitud revisan su información en tiempo real para
      determinar si la información proporcionada, cumple con los
      criterios para adquirir un auto. Los vendedores participantes
      podrán , entre otras cosas, verificar su identidad y revisar
      su información en bases de datos nacionales (como Experian,
      Llamada de crédito y otros) que rastrea las transacciones de
      consumo con las instituciones de crédito. Al enviar el
      formulario (s) de solicitud, usted está de acuerdo en permitir
      que los vendedores participantes revisen, verifiquen e
      investiguen dicha información.`,
      termsParagraph6a: `Si un vendedor participante determina que su solicitud cumple
      con sus criterios de calificación de para obtener un auto, la
      página web`,
      termsParagraph6b: `abrirá una nueva página web que identifica el nombre (s) y la
      información del vendedor (s) de contacto, o redirigirá el
      navegador a la página web de los vendedores. Usted entiende y
      acepta que, una vez haya sido redirigido a la página web de la
      entidad vendedora,`,
      termsParagraph6c: ` no tiene
      más participación en la solicitud de compra de auto. Sus
      interacciones subsecuentes con el prestamista están sujetas a
      la política de privacidad, condiciones de uso, y otras
      políticas y / o términos impuestos por ese prestamista.`,
      termsParagraph7a: ` no es un
      vendedor de autos, no acredita personas ni toma decisiones de
      crédito en relación con su servicio de búsqueda de automovil.`,
      termsParagraph7b: ` no
      garantiza la validacion de un precio en particular de un auto
      ni establece las condiciones de lla compra especifica con
      cualquier prestamista participante.`,
      termsParagraph7c: `tampoco garantiza que el precio, los productos,
      disponibilidad, tarifas, tasas u otros términos de otros autos
      ofrecidos y puestos a disposición por los vendedores
      participantes, sean las mejores condiciones disponibles en el
      mercado.`,
      termsParagraph8a: `no avala
      ni recomienda los productos de cualquier vendedor particular.`,
      termsParagraph8b: ` es un
      agente suyo o de cualquier vendedor participante.`,
      termsParagraph8c: ` no está involucrado con el uso de la entidad
      crediticia y / o revisión de la información de su solicitud
      para tomar una determinación acerca de si usted cumple o no
      con los criterios de calificación del para comprar el auto ,
      de un vendedor particular. El vendedor es el único responsable
      de sus servicios hacia usted y usted acepta que`,
      termsParagraph8d: ` no será
      responsable de los daños o gastos de cualquier tipo que surjan
      relacionados de alguna manera mediante su uso del servicio de
      búsqueda de auto. Entiende que los vendedores participantes
      podrán mantener la información de su solicitud, tanto si está
      como si no está calificado para una compra de automovil con
      ellos.`,
      terms9Header: `Derechos de Autor`,
      termsParagraph9a: `El contenido, organización, gráficos, diseño, compilación,
      traducción magnética, conversión digital y otros asuntos
      relacionados con la página están protegidos por derechos de
      autor, marcas registradas y otros derechos de propiedad
      (incluyendo la propiedad intelectual, aunque no es el único).
      La copia, redistribución, uso o publicación por su parte de
      tales asuntos o cualquier parte de la página web, excepto lo
      permitido por "Derecho de uso limitado" a continuación, está
      estrictamente prohibido. Usted no adquiere derechos de
      propiedad sobre cualquier contenido, documento u otros
      materiales vistos a través de la página. La publicación por`,
      termsParagraph9b: ` de
      información o materiales en la página no constituye una
      renuncia a cualquier derecho sobre dicha información y
      materiales.`,
      terms10Header: `Derecho de autor y marca de servicio de información:`,
      termsParagraph10a: `Derechos de autor © Multigenios SA de CV 2018, todos los
      derechos reservados. “`,
      termsParagraph10b: `” y los logotipos asociados son marcas de servicio de`,
      termsParagraph10c: ` y están
      protegidos en Estados Unidos, Reino Unido, España y México.
      Todas las demás marcas son propiedad de sus respectivos
      dueños.`,
      terms11Header: `Derecho de uso limitado:`,
      termsParagraph11a: `La visualización, impresión o descarga de cualquier contenido,
      video, audio, gráfico, formulario o documento desde la página
      le concede solamente una licencia no exclusiva y revocable
      para el uso exclusivamente personal y no comercial, según sea
      necesario para ver y escuchar el contenido y navegar a través
      de las páginas y enlaces disponibles en general al público
      mediante un navegador estándar de Internet y reproductor
      multimedia estándar, y no para la re publicación,
      distribución, cesión, sub-licencia, venta, preparación de
      trabajos derivados o cualquier otro uso. Ninguna parte de
      cualquier contenido, gráfico, formulario o documento puede ser
      reproducida en cualquier forma ni se puede incorporar en
      cualquier sistema de recuperación de información, ya sea
      electrónico o mecánico, que no sea para su uso personal y no
      comercial (pero no para la reventa o redistribución).`,
      terms12Header: `Edición, eliminación y modificación:`,
      termsParagraph12a: `Nos reservamos el derecho a nuestra discreción, de cambiar,
      modificar o eliminar cualquier documento, información u otro
      contenido que aparezca en la página.`,
      terms13Header: `Indemnización:`,
      termsParagraph13a: `Usted acepta eximir a`,
      termsParagraph13b: ` y sus
      respectivos directores, funcionarios, accionistas, empleados,
      agentes, abogados, anunciantes y editores (en conjunto,
      "Nuestras Partes Afiliadas") de cualquier responsabilidad,
      pérdida, reclamación o gastos, incluyendo los honorarios
      razonables de abogados, relacionados con su uso de la página o
      violación de este Acuerdo`,
      terms14Header: `Derecho Intransferible:`,
      termsParagraph14a: `Su derecho a utilizar la página no es transferible. Cualquier
      contraseña o derecho otorgado a usted para obtener información
      o documentos desde la página no es transferible.`,
      terms15Header: `Renuncia de garantía y limitación de responsabilidad :`,
      termsParagraph15a: `Los programas informáticos, información y demás contenidos
      disponibles a través de la página se proporcionan "tal cual",
      "según disponibilidad", y todas las garantías, expresas o
      implícitas, son rechazadas. En la medida de lo permitido por
      la ley aplicable,`,
      termsParagraph15b: ` rechaza toda garantía expresa o implícita, incluyendo,
      pero no limitándose a, (a) que ningún programa informático o
      la información disponible en, o a través de esta página, o la
      página misma sea comercial o adecuado para un propósito
      particular, (b ) que los programas o información disponible
      en, o a través de este sitio, o el sitio mismo será
      ininterrumpido o se verá libre de errores , (c) que los
      defectos serán corregidos, (d ) que no existen virus u otros
      componentes nocivos informáticos , ( e) que los métodos de
      seguridad empleados serán suficientes, (f) en relación con
      corrección, precisión o confiabilidad o (g) perjuicio del uso
      y disfrute de los programas o datos informáticos, o contra
      infracción. Se ofrece toda información y programas
      informáticos prestados en el curso de este acuerdo con todos
      sus defectos, y el riesgo en cuanto a la calidad, rendimiento,
      precisión y el esfuerzo del usuario. Nosotros y nuestros
      socios no tenemos ninguna responsabilidad respecto al uso de
      la información o cualquier otro contenido disponible desde la
      página, o en la página en sí. En particular, aunque no como
      una limitación del mismo, nosotros y nuestros socios no somos
      responsables por daños y perjuicios, daños indirectos,
      especiales o emergentes (incluidos los daños por pérdida de
      negocio, pérdida de beneficios, litigios o similares), ya sea
      basado en el incumplimiento del contrato, el incumplimiento de
      la garantía, agravio, responsabilidad del producto; ni por
      cualquier reclamación de terceros de cualquier naturaleza,
      incluso si se ha advertido de la posibilidad de tales daños o
      reclamos. La negación de daños establecida arriba es un
      elemento fundamental de la base de la negociación entre México
      y usted. La información y otro contenido está disponible en, o
      a través de esta página, y la página en sí, que no se
      ofrecería sin tales limitaciones. Ningún consejo o
      información, ya sea oral o escrita, que obtenga de México a
      través de la página creará ninguna garantía, representación o
      garantía que esté estipulada expresamente en este acuerdo.`,
      terms16Header: `Los enlaces a otras páginas web:`,
      termsParagraph16a: `La página puede ahora, o en el futuro, contener enlaces a
      páginas web de terceros. Nosotros no controlamos,
      investigamos, supervisamos o comprobamos dichas páginas web,
      no nos hacemos responsables de los programas de ordenador
      disponibles, contenido u opiniones expresadas en dichas
      páginas web, y no las investigamos, supervisamos o
      comprobamos. Proporcionamos dichos enlaces de terceros
      únicamente para comodidad de los visitantes de la página, y la
      inclusión de un enlace no implica la aprobación o la
      des-aprobación de la página vinculada por nosotros. Si usted
      decide abandonar la página y acceder a cualquier página web de
      terceros, lo hace bajo su propio riesgo.`,
      terms17Header: `Información y Comunicados de prensa:`,
      termsParagraph17a: `La página puede contener entrevistas, debates, comunicados de
      prensa y otra información (colectivamente, "Información")
      acerca de nosotros, nuestra empresa y nuestros servicios,
      incluyendo enlaces a páginas web de terceros que contengan
      dicha información, que se esté proporcionando a conveniencia
      de los visitantes de la página. A pesar de que toda la
      información preparada por`,
      termsParagraph17b: ` se cree
      exacta a partir de la fecha de preparación, negamos cualquier
      derecho u obligación de actualizar cualquier información. No
      se debe confiar en que declaraciones referentes a empresas
      distintas de`,
      termsParagraph17c: ` que estén
      contenidas en dicha información fueran facilitadas o endosadas
      por nosotros. Las opiniones expresadas en cualquier
      información, incluyendo los empleados y agentes de`,
      termsParagraph17d: `, son
      exclusivamente las del autor y no reflejan necesariamente el
      parecer de`,
      termsParagraph17e: `. Con
      respecto a la información que figura en los enlaces a páginas
      web de terceros, por favor consulte la sección "Enlaces a
      otras páginas web" arriba.`,
      terms18Header: `Comunicaciones de Visitantes:`,
      termsParagraph18a: `Salvo disposición expresa en contra nuestra, todos los
      comentarios, retroalimentación, información o materiales que
      usted envíe a través o en asociación con la página se
      considerará no confidencial. Al enviarnos dichos comentarios,
      opiniones, información o material:`,
      privacyParagraph18ali1a: ` Usted manifiesta y garantiza que el uso de`,
      privacyParagraph18ali1b: `es bajo su responsabilidad y no contraviene ningún acuerdo,
      no viola ninguna ley, o infringe los derechos de algún tercero`,
      privacyParagraph18ali2a: ` Usted manifiesta y garantiza que tiene
      todos los derechos para aceptar este acuerdo`,
      privacyParagraph18ali3a: ` Usted acepta que`,
      privacyParagraph18ali3b: `es libre de utilizar de cualquier forma la totalidad o parte
      de los contenidos de cualquier comunicación sin
      restricciones y sin la obligación de notificárselo,
      identificar o compensárselo de cualquier otra forma;`,
      privacyParagraph18ali4a: ` Usted concede a`,
      privacyParagraph18ali4b: `todos los derechos necesarios, incluyendo la renuncia a toda
      la privacidad y los derechos morales, a utilizar todos los
      comentarios, retroalimentación, información o material, en
      su totalidad o en parte, o como una obra derivada, sin
      ninguna obligación por parte`,
      privacyParagraph18ali4c: ` a cualquiera que sea. No aceptamos ideas no solicitadas, obras
      u otros materiales y usted reconoce que usted es responsable
      de asumir todos los riesgos relativos a la utilización o
      distribución de dichas ideas, obras o materiales.`,
      privacyParagraph18ali5a: `prohíbe expresamente la localización de las direcciones de
      correo electrónico desde cualquier página web (incluyendo
      todas las áreas de esta página - miembros y no miembros) y
      opta expresamente por no recibir mensajes de correo
      electrónico comerciales dirigidos a direcciones de correo
      electrónico de`,
      privacyParagraph18ali5b: `con dominio en México que se hayan obtenido en violación de
      lo anterior, o mediante el uso de software automático de
      generación de direcciones.`,
      terms19Header: `Cookies:`,
      termsParagraph19a: ` hace uso de cookies. Las cookies son archivos que identifican en
      nuestro servidor a su ordenador. Las cookies por sí mismas no
      identifican al usuario individual, sino al ordenador
      utilizado. Muchas páginas hacen esto cada vez que un usuario
      visita su página con el fin de realizar un seguimiento de los
      flujos de tráfico. Los cookies propios sólo registran aquellas
      áreas de la página que hayan sido visitadas por el equipo y
      durante cierto tiempo. Usted puede evitar el uso de cookies
      mediante la configuración de su navegador web, pero tenga en
      cuenta que esto puede dificultar algunas de las
      funcionalidades de la página web.`,
      terms20Header: `Datos, Terceros y cumplimiento de la Ley de Protección de
      Datos Personales en Posesión de Terceros:`,
      termsParagraph20a: `Toda la información presentada en esta página se lleva a cabo
      de manera segura y transfiere a las partes interesadas a
      través de un servidor seguro, de conformidad con la Ley de
      Protección de Datos de 1998. Si pasamos su información a
      terceros nos aseguramos de que su información personal será
      tratada con la misma seguridad y confidencialidad`,
    },
  },
});
export default i18n;
