
import { defineComponent, ref, onBeforeMount } from "vue";
import PanelMenu from "primevue/panelmenu";
import Carousel from "primevue/carousel";
import i18n from "../locales/i18n";
import "particles.js";
import * as FacebookServices from "../api/helpers/Facebook";
import _ from "underscore";
declare global {
  interface Window {
    particlesJS: any;
  }
}

window.particlesJS = window.particlesJS || {};
export default defineComponent({
  name: "Home",
  props:{
    language: String
  },
  components: {
    PanelMenu,
    Carousel,
  },
  setup(props) {
    var items = ref([{}]);
    var clients = ref([{}]);
    const getItems = async () => {
      items.value = [
        {
          key: "0",
          label: i18n.global.messages[i18n.global.locale].workStepsHeader1,
          items: [
            {
              label: i18n.global.messages[i18n.global.locale].workSteps1,
              url: "registro",
            },
          ],
        },
        {
          key: "1",
          label: i18n.global.messages[i18n.global.locale].workStepsHeader2,
          items: [
            {
              label: i18n.global.messages[i18n.global.locale].workSteps2,
            },
          ],
        },
        {
          key: "2",
          label: i18n.global.messages[i18n.global.locale].workStepsHeader3,
          items: [
            {
              label: i18n.global.messages[i18n.global.locale].workSteps3,
            },
          ],
        },
      ];
      clients.value = [
        {
          name: "Bernardo Ventura",
          description: i18n.global.messages[i18n.global.locale].client1,
          image: "/img/testi-1.09249421.jpg",
        },
        {
          name: "Kathy Morris",
          description: i18n.global.messages[i18n.global.locale].client2,
          image: "/img/testi-3.62c4ea23.jpg",
        },
        {
          name: "Nazzmul Araj",
          description: i18n.global.messages[i18n.global.locale].client3,
          image: "/img/testi-2.a6abc48c.jpg",
        },
      ];
    }
    return {
      items,
      clients,
      getItems
    };
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      expandedKeys: { 0: true },
      itemsSteps: [
        {
          label: "",
          to: "/",
        },
        {
          label: "",
          to: "home",
        },
        {
          label: "",
          to: "/home",
        },
      ],
    };
  },
  watch: {
    expandedKeys(newExpanded, oldExpanded) {
      if (!_.isEmpty(_.omit(newExpanded, _.keys(oldExpanded))))
        this.expandedKeys = _.omit(newExpanded, _.keys(oldExpanded));
    },

    language(value) {
      this.getItems();
    },
  },
  mounted() {
    this.getItems();
    FacebookServices.pageView();
    this.initParticles();
    sessionStorage.clear();
    localStorage.clear();
    const urlParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem("SID", urlParams.get("SID") || "");
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
});
