<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />

    <div class="dialog-language">
      <ConfirmDialog group="language">
        <template #message="slotProps">
          <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{slotProps.message.message}}</p>
          </div>
        </template>
      </ConfirmDialog>
    </div>

    <section id="home" class="menubar-container d-flex">
      <div class="menu-container">
        <Menubar :model="items">
          <template #start>
            <router-link :to="{ name: 'Home' }">
              <div class="row">
                <div class="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                  <img
                    width="140"
                    class="logo-default"
                    src="./assets/images/logo.png"
                    :alt="name"
                  />
                </div>
              </div>
            </router-link>
          </template>
          <template #end>
            <div class="bg-black-p-component p-r-7rem pt-2">
              <Dropdown
                :options="languages"
                @change="setLanguage"
                v-model="$i18n.locale"
                optionLabel="name"
                optionValue="value"
              />
            </div>
          </template>
        </Menubar>
      </div>
    </section>
    <router-view class="view" :language="$i18n.locale"/>
    <footer class="main-footer pt-100">
      <section>
        <div class="container">
          <div class="row d-md-flex flex-wrap align-items-center mb-4">
            <div class="lqd-column col-md-4 col-md-offset-1 col-sm-12">
              <ul class="social-icon social-icon-md mb-25">
                <li>
                  <a :href="fbPage" target="_blank">
                    <i class="pi pi-facebook"></i>/{{ name }}
                  </a>
                </li>
                <br><br>
                <li>
                  <img
                    width="80"
                    height="10px"
                    class="logo-default"
                    src="./assets/images/flag-icon.png"
                    :alt="name"
                  />
                </li>
              </ul>
            </div>

            <div class="lqd-column col-md-2 col-md-offset-2 col-xs-12">
              <h3 class="widget-title">
                {{$t("navigation")}}
              </h3>
              <ul class="lqd-custom-menu reset-ul font-size-16 lh-2">
                <li><a href="/#">Home</a></li>
                <li>
                  <a href="/#work">{{ $t("work").charAt(0).toUpperCase() + $t("work").substring(1).toLowerCase()}}</a>
                </li>
                <li>
                  <a href="/#benefits">{{ $t("benefits").charAt(0).toUpperCase() + $t("benefits").substring(1).toLowerCase()}}</a>
                </li>
              </ul>
            </div>

            <div class="lqd-column col-md-3 col-xs-12">
              <h3 class="widget-title">
               {{ $t("about") }}
              </h3>
              <ul class="lqd-custom-menu reset-ul font-size-16 lh-2">
                <li>
                  <a href="/terminos-y-condiciones">{{ $t("terms") }}</a>
                </li>
                <li>
                  <a href="/aviso-de-privacidad">{{ $t("privacy") }}</a>
                </li>
                <li>&emsp;</li>
              </ul>
            </div>
          </div>

          <div class="row text-center font-size-16 py-5 my-3">
            <div class="col-xs-12">
              {{ $t("office") }}
              <br />
              {{ new Date().getUTCFullYear() }} ©
              <span class="text-secondary font-weight-bold">
                {{ name }}
              </span>
              -
              {{ $t("rights") }}
            </div>
          </div>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import { ref } from "vue";
import ConfirmDialog from 'primevue/confirmdialog';
import Menubar from "primevue/menubar";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import i18n from "./locales/i18n";
import _ from "underscore";
export default {
  components: {
    Menubar,
    Toast,
    Dropdown,
    ConfirmDialog
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    i18n.global.locale = sessionStorage.getItem("language") || "en";
    sessionStorage.setItem("language", i18n.global.locale);
    var languages = ref([{}]);
    var items = ref([{}]);

    const getLanguages = async () => {
      languages.value =
        i18n.global.locale == "en"
          ? [
              { value: "en", name: "English" },
              { value: "es", name: "Spanish" },
            ]
          : [
              { value: "en", name: "Inglés" },
              { value: "es", name: "Español" },
            ];
    }

    const getItems = async () => {
      items.value = [
        {
          label: "🇨🇦"
        },
        {
          label: "HOME",
          to: "/#home",
        },
        {
          label: i18n.global.messages[i18n.global.locale].work,
          to: "/#work",
        },
        {
          label: i18n.global.messages[i18n.global.locale].benefits,
          to: "/#benefits",
        },
      ];
    }
    return {
      toast,
      year,
      items,
      getItems,
      languages,
      getLanguages
    };
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
    };
  },
  mounted () {
    this.initLanguage();
    this.getLanguages();
  },
  methods: {
    setLanguage() {
      sessionStorage.setItem("language",i18n.global.locale);
      this.getItems();
      this.getLanguages();
    },
    initLanguage() {
      if (!sessionStorage.getItem("first-visit")) {
        this.$confirm.require({
          group: 'language',
          acceptLabel: 'ESPAÑOL',
          rejectLabel: 'ENGLISH',
          acceptClass: 'p-dialog-button',
          rejectClass: 'p-dialog-button',
          accept: () => {
            i18n.global.locale = "es";
            sessionStorage.setItem("first-visit", true);
            this.setLanguage();
          },
          reject: () => {
            i18n.global.locale = "en";
            sessionStorage.setItem("first-visit", true);
            this.setLanguage();
          },
          onHide: () => {
            i18n.global.locale = "en";
            sessionStorage.setItem("first-visit", true);
            this.setLanguage();
          }
        });
      }
    }
  },
};
</script>

<style>
  .list-footer {
    list-style-type: none;
    text-align: left;
  }

  .list {
    color: #058fcc;
    font-size: 12px;
  }

  .p {
    color: white;
    font-size: 14px;
    margin: 1em;
  }

  .bg-black {
    background-color: #000;
  }

  i {
    font-size: 19px;
  }

  .view {
    width: 100%;
  }
  .menu-container {
    width: 100%;
    position: relative;
  }
  @media (min-width: 945px) {
    .p-menubar-start {
      width: 55%;
    }
    .flag {
    padding-top: 3px;
    }
  }
  @media (max-width: 945px) and (min-width: 768px) {
    .p-menubar-start {
      width: 80% !important;
    }
  }
   @media (max-width: 767px) and (min-width: 0px) {
    .p-menubar-start {
      width: 78% !important;
    }
  }
  /* @media (min-width: 1825px) {
    .p-menubar-start {
      width: 55%;
    }
  } */
  .p-menubar {
    z-index: 100;
    width: 100%;
    top: 0;
    background: black !important;
    border: transparent !important;
    margin-top: 30px;
  }

  .p-menubar .p-menubar-button {
    position: absolute;
    line-height: 50px;
    left: 80%;
  }

  .p-menubar .p-menubar-button:hover {
    font-size: 2rem;
    background: black !important;
    color: #fff !important;
    outline: 10px solid black !important;
  }

  .p-menubar .p-menubar-button .pi {
    font-size: 2rem;
    background: black !important;
    color: #fff !important;
  }

  .p-menubar .p-menubar-root-list {
    position: absolute;
    right: 0px;
    background: black !important;
    border: 1px solid black !important;
    width: 45%;
  }

  .p-menubar .p-menubar-root-list :hover {
    background: black !important;
    border: 1px solid black !important;
    text-decoration: underline 6px solid #ffb09f;
  }

  .p-menubar .p-menubar-root-list :focus {
    background: black !important;
    border: 1px solid black !important;
    text-decoration: underline 6px solid #ffb09f;
  }

  .p-menuitem-text {
    color: #ffffff !important;
    font-family: "Glacial Indifference", sans-serif !important;
  }

  .p-menuitem-text-menubar {
    color: #ffffff !important;
    font-family: "Glacial Indifference", sans-serif !important;
  }

  @media (min-width: 1825px) {
    .footer {
      padding: 0 25rem;
    }
  }

  .p-fb {
    margin-top: 0;
  }

  .p-error {
    color: #ffb09f !important;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--color-secondary) !important;
  }

  .p-success {
    color: var(--color-secondary) !important;
  }

  .p-dropdown .p-dropdown-label {
    margin-top: -5px;
  }

  .social-icon i {
    color: var(--color-link);
  }

  .bg-black-p-component .p-dropdown .p-dropdown-label {
    background: black;
    color: white;
  }

  .bg-black-p-component .p-component.p-component * {
    box-sizing: border-box;
    background-color: black;
    border: black;
  }

  .bg-black-p-component .p-dropdown {
    border: black !important;
  }

  .p-r-7rem {
    padding-right: 7rem;
  }
  .p-dialog {
    border: 2px solid #ffffff !important;
    border-radius: 5px !important;
  }
  .p-dialog .p-dialog-header {
    background: black !important;
    color: #ffffff !important;
    display: none;
  }
  .p-dialog .p-dialog-content {
    background: black !important;
    color: #ffffff !important;
    display: none;
  }
  .p-dialog .p-dialog-footer {
    background: black !important;
    color: #ffffff !important;
    padding: 3rem 2rem !important;
  }
  .p-dialog .p-dialog-button {
    color: #ffb09f;
    border: 1px solid var(--color-primary);
    border-radius: 50em;
    background-color: transparent;
  }
  .p-dialog .p-dialog-button .p-button-label {
    font-size: 12px;
    font-weight: 700;
    padding: 0.5em 3em;
  }
  .p-dialog .p-button:hover {
    background: #ffb09f !important;
    border-color: #ffb09f !important;
    border: 1px solid var(--color-primary);
  }
  .p-dialog .p-button:active {
    background: #ffb09f !important;
    border-color: #ffb09f !important;
    border: 1px solid var(--color-primary);
  }
  .p-dialog .p-button:focus {
    box-shadow: none;
  }

</style>
